/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */

import { Route, Redirect } from 'react-router-dom';
import userSignal from 'signals/User.Signal';
import { REDIRECT_PATHS } from '../../../../constants';

const PrivateRoute = ({ component: Component, type, layout: Layout, ...rest }) => {
  const { isSignedIn, userType } = userSignal.value;

  const redirect = () => {
    if (isSignedIn) {
      return REDIRECT_PATHS[userType];
    }

    return '/';
  };

  return (
    <Route
      {...rest}
      render={(props) => (
        (
          (isSignedIn && (type === userType || type === 'any'))
            || userType === 'dev') ? (Layout ? <Layout><Component {...props} /></Layout> : <Component {...props} />) : <Redirect to={redirect()} />
      )}
    />
  );
};

export default PrivateRoute;
