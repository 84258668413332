import Fetcher from 'libs/fetcher';
import userSignal from 'signals/User.Signal';
import { auth } from './firebase';
import displayNotification from './displayNotification';

const fetcher = new Fetcher();
const DEFAULT_INITIAL_ONBOARDING_ROUTE = '/on-boarding/select-user-type';

const registerFirebaseAuthListener = () => auth.onAuthStateChanged(async (authUser) => {
  try {
    if (authUser === null) {
      userSignal.reset();
      userSignal.update({
        isLoading: false,
        isSignedIn: false,
      });
      return;
    }

    // USER EXISTS IN FIREBASE AND WE MAY PROCEED

    const getUserQuery = {
      path: '/users',
      query: {
        where: {
          uid: authUser?.uid,
        },
        include: {
          user_type: true,
          ethnicity: true,
          gender: true,
          age: true,
          users_on_other_conditions: true,
          organization: {
            include: {
              founder_org: true,
              investor_org: true,
            },
          },
        },
      },
    };

    let user = await fetcher.query(getUserQuery);

    if (!user?.[0]) {
      await fetcher.post({
        path: '/users',
        body: {
          data: {
            uid: authUser.uid,
            email: authUser.email,
            onboarding_step: DEFAULT_INITIAL_ONBOARDING_ROUTE,
          },
        },
      });
      user = await fetcher.query(getUserQuery);
    }

    user = user?.[0];

    userSignal.update({
      ...user,
      userType: user?.user_type?.description?.toLowerCase(),
      isSignedIn: true,
      isLoading: false,
    });
  } catch (e) {
    displayNotification({
      message: e.message,
    });
  }
});

export default {
  registerFirebaseAuthListener,
};
