/* eslint-disable global-require */
import React, { Suspense } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import './scss/style.scss';
import Loader from 'components/global/Loader';
import PublicRoute from 'components/global/Routes/PublicRoute';
import PrivateRoute from 'components/global/Routes/PrivateRoute';
// import Form from 'components/views/Form';
import userSignal from 'signals/User.Signal';
import Notifier from './components/global/Notifier/Notifier';
import initApp from './utils/initApp';
import { REDIRECT_PATHS } from './constants';
import routes from './config/routes';

initApp.registerFirebaseAuthListener();

const LoadingSuspense = () => (
  <div className="min-vh-100 w-100 d-flex justify-content-center align-items-center flex-grow-1">
    <Loader message="" className="text-center" />
  </div>
);

const UserRoutes = ({ routes: appRoutes }) => (
  <Switch>
    <Suspense fallback={<LoadingSuspense />}>
      {appRoutes.map((route) => {
        let routeFile;
        try {
          routeFile = import(`./${route.viewPath}`);
        } catch (error) {
          console.error('ERROR', error);
          return null;
        }

        const component = React.lazy(() => routeFile);
        const layout = route.layout ? React.lazy(() => import(`./${route.layout}`)) : null;

        if (route.router === 'private') {
          return <PrivateRoute path={route.urlPath} component={component} key={route.viewPath} type={route.type} layout={layout} />;
        }

        return <PublicRoute path={route.urlPath} component={component} key={route.viewPath} />;
      })}
    </Suspense>
  </Switch>
);

const App = () => {
  const { isLoading: loading, onboarding_step: onboardingStep, isSignedIn, userType } = userSignal.value;

  if (loading) {
    return (
      <div className="min-vh-100 w-100 d-flex justify-content-center align-items-center flex-grow-1">
        <Loader message="" className="text-center" />
      </div>
    );
  }

  if (isSignedIn === false) {
    return (
      <>
        <Router>
          <Route component={() => <UserRoutes routes={routes.public} />} />
          <Redirect to="/signup" />
        </Router>
      </>
    );
  }

  return (
    <>
      <Notifier />
      <Router>
        <Switch>
          <Route component={() => <UserRoutes routes={routes[userType ?? 'all']} />} />
        </Switch>
        {onboardingStep === 'completed' && userType !== 'dev' && <Redirect to={REDIRECT_PATHS[userType]} />}
        {/* Handle Edge Cases */}
        {onboardingStep !== 'completed' && userType !== 'dev' && <Redirect to={onboardingStep} />}

      </Router>
    </>
  );
};

export default App;
