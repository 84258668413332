/* eslint-disable */
import { handleHttpError } from '../utils/httpErrors';
import { getConfig } from '../config/config';
import exception from '../utils/exception';
import { ENVS_WITH_LOG_ENABLE, METHODS_WITH_BODY } from '../constants';

const env = getConfig('ENV');

const globalHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};
const apiUrl = getConfig('LOCAL_API_ENDPOINT');

class Fetcher {
  constructor({
    baseEndpoint,
    headers = {},
    enableLog = false,
    checkApiAvailabilityBeforeRequest = false,
    checkApiAvailabilityBeforeRequestEndpoint = '/heartbeat'
  } = {}) {
    this.baseUrl = baseEndpoint ?? apiUrl;
    this.headers = headers;
    this.shoudLog = enableLog && ENVS_WITH_LOG_ENABLE?.includes(env);
    this.checkApiAvailabilityBeforeRequest = checkApiAvailabilityBeforeRequest;
    this.checkApiAvailabilityBeforeRequestEndpoint = checkApiAvailabilityBeforeRequestEndpoint;
  }

  log({ label = 'Fetcher: ', data } = {}) {
    if (this.shoudLog) {
      if (data) {
        console.log(label, data);
        return;
      }

      console.log(label);
    }
  }

  async checkRequestIntegrity(response) {
    if (!response.ok) {
      const data = (await response.json()) ?? {};

      throw exception({
        message: data?.error?.message ?? response.statusText,
        status: data?.error?.status ?? response.status,
      });
    }
  }

  async checkApiAvailability({ headers = {} }) {
    try {
      if (!this.checkApiAvailabilityBeforeRequest) return;

      this.log({
        label: 'Cheking API availability.',
      });

      const availabilityResponse = await fetch(`${this.baseUrl}${this.checkApiAvailabilityBeforeRequestEndpoint}`, {
        headers: {
          ...headers,
        },
      });

      this.checkRequestIntegrity(availabilityResponse);
    } catch (e) {
      throw exception({
        message:
          'API Unreachable. Either API is down or you dont have enough permissions.',
        status: 500,
      });
    }
  }

  async fetcher({
    method,
    path,
    body,
    headers,
    query,
    verb,
  } = {}) {
    const requestUrl = `${this.baseUrl}${path}`;
    let requestQuery = query ? `?${new URLSearchParams(query).toString()}` : '';

    if (verb && verb === 'runQuery') {
      if (!query) {
        throw exception({
          message: 'Request Body is required.',
          status: 400,
        });
      }
      requestQuery = `/runQuery?query=${JSON.stringify(query)}`;
    }

    if (METHODS_WITH_BODY.includes(method) && !body) {
      throw exception({
        message: 'Request Body is required.',
        status: 400,
      });
    }

    const reqBody = {
      method,
      headers: {
        ...globalHeaders,
        ...this.headers,
        ...headers,
      },
    };

    if (METHODS_WITH_BODY.includes(method)) {
      reqBody.body = JSON.stringify(body);
    }

    await this.checkApiAvailability({ headers })

    this.log({
      label: 'Fetcher Request: ',
      data: {
        baseurl: this.baseUrl,
        path,
        requestUrl,
        ...reqBody,
      },
    });

    const req = await fetch(`${this.baseUrl}${path}${requestQuery}`, reqBody);

    await this.checkRequestIntegrity(req);

    const data = (await req.json()) ?? {};

    handleHttpError({
      ...data,
      reqBody: {
        ...reqBody,
        requestUrl,
      },
    });

    this.log({
      label: 'Fetcher Response: ',
      data: {
        data,
        requestUrl,
      },
    });

    return data;
  }


  // Bootstrapping

  async get({ path = '', query, headers = {} }) {
    const req = await this.fetcher({
      method: 'GET',
      path,
      headers,
      query,
    });

    return req;
  }

  async delete({ path = '', body, query, headers = {} }) {
    const req = await this.fetcher({
      method: 'DELETE',
      path,
      headers,
      query,
      body
    });

    return req;
  }

  async post({ body, path = '', query, headers = {} }) {
    const req = await this.fetcher({
      method: 'POST',
      path,
      body,
      headers,
    });

    return req;
  }

  async put({ body, path = '', query, headers = {} }) {
    const req = await this.fetcher({
      method: 'PUT',
      path,
      body,
      headers,
      query,
    });

    return req;
  }

  async patch({ body, path = '', query, headers = {} }) {
    const req = await this.fetcher({
      method: 'PATCH',
      path,
      body,
      headers,
      query,
    });

    return req;
  }

  async query({ path = '', query, headers = {} } = {}) {
    const req = await this.fetcher({
      method: 'GET',
      path,
      query,
      headers,
      verb: 'runQuery'
    });

    return req;
  }
}

export default Fetcher;