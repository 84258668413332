/* eslint-disable react/jsx-props-no-spreading */
import { Route, Redirect } from 'react-router-dom';
import userSignal from 'signals/User.Signal';

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  const isSignedIn = userSignal.valueOf('isSignedIn');

  return (
    <Route
      {...rest}
      render={(props) => (
        isSignedIn && restricted
          ? <Redirect to="/" />
          : <Component {...props} />
      )}
    />
  );
};
export default PublicRoute;
