const routes = [
  {
    viewPath: 'components/views/Signup',
    router: 'public',
    urlPath: '/signup',
  },
  {
    viewPath: 'components/views/Login',
    router: 'public',
    urlPath: '/login',
  },
  {
    viewPath: 'components/views/InvestorDashboard',
    router: 'private',
    type: 'investor',
    urlPath: '/investor/dashboard',
  },
  {
    viewPath: 'components/views/InvestorDealFlow',
    router: 'private',
    type: 'investor',
    urlPath: '/investor/deals',
  },
  {
    viewPath: 'components/views/InvestorProfile',
    router: 'private',
    type: 'investor',
    urlPath: '/investor/profile',
  },
  {
    viewPath: 'components/views/InvestorSettings',
    router: 'private',
    type: 'investor',
    urlPath: '/investor/settings',
  },
  {
    viewPath: 'components/views/InvestorAlerts',
    router: 'private',
    type: 'investor',
    urlPath: '/investor/alerts',
  },
  {
    viewPath: 'components/views/InvestorTasks',
    router: 'private',
    type: 'investor',
    urlPath: '/investor/tasks',
  },
  {
    viewPath: 'components/views/InvestorPortfolio',
    router: 'public',
    type: 'investor',
    urlPath: '/investor/portfolio',
  },
  {
    viewPath: 'components/views/OnboardingShare',
    router: 'private',
    type: 'any',
    urlPath: '/on-boarding/share',
  },
  {
    viewPath: 'components/views/OnboardingCompanyInfo',
    router: 'private',
    type: 'any',
    urlPath: '/on-boarding/company-info',
  },
  {
    viewPath: 'components/views/OnboardingInvestorInfo',
    router: 'private',
    type: 'investor',
    urlPath: '/on-boarding/investor-info',
  },
  {
    viewPath: 'components/views/FounderEditRound',
    router: 'private',
    type: 'founder',
    urlPath: '/founder/round/:id',
  },
  {
    viewPath: 'components/views/FounderDashboard',
    router: 'private',
    type: 'founder',
    urlPath: '/founder/dashboard',
  },
  {
    viewPath: 'components/views/FounderCompanyInfo',
    router: 'private',
    type: 'founder',
    urlPath: '/founder/company-info',
  },
  {
    viewPath: 'components/views/FounderSettings',
    router: 'private',
    type: 'founder',
    urlPath: '/founder/settings',
  },
  {
    viewPath: 'components/views/FounderPipeline',
    router: 'public',
    type: 'founder',
    urlPath: '/founder/pipeline',
  },
  {
    viewPath: 'components/views/FounderAlerts',
    router: 'private',
    type: 'founder',
    urlPath: '/founder/alerts',
  },
  {
    viewPath: 'components/views/FounderTasks',
    router: 'private',
    type: 'founder',
    urlPath: '/founder/tasks',
  },
  {
    viewPath: 'components/views/PublicProfileFounder',
    router: 'public',
    urlPath: '/public/founder/:id',
  },
  {
    viewPath: 'components/views/PublicProfileInvestor',
    router: 'private',
    urlPath: '/public/investor/:id',
  },
  {
    viewPath: 'components/views/FounderCreateRound',
    router: 'private',
    urlPath: '/founder/create/round',
  },
  {
    viewPath: 'components/views/FounderDealShared',
    router: 'public',
    urlPath: '/deal/:id',
  },
  {
    viewPath: 'components/views/PublicProfileFund',
    router: 'public',
    urlPath: '/fund/profile/:id',
  },
  {
    viewPath: 'components/views/InvestorEditProfile',
    router: 'public',
    urlPath: '/investor/edit-profile/:id',
  },
  {
    viewPath: 'components/views/FounderEditProfile',
    router: 'public',
    urlPath: '/founder/edit-profile/:id',
  },
  {
    viewPath: 'components/views/InvestorPipeline',
    router: 'public',
    urlPath: '/investor/pipeline',
  },
  {
    viewPath: 'components/views/InvestorPipelineDetail',
    router: 'public',
    type: 'investor',
    urlPath: '/investor/pipeline-detail/:id',
  },
  {
    viewPath: 'components/views/FounderPipelineDetail',
    router: 'private',
    type: 'founder',
    urlPath: '/founder/pipeline-detail/:id',
  },
  {
    viewPath: 'components/views/Deals',
    router: 'public',
    urlPath: '/founder/deals',
  },
  {
    viewPath: 'components/views/EditDeal',
    router: 'public',
    urlPath: '/founder/deal/:id',
  },
  {
    viewPath: 'components/views/FounderSharedCompanyProfile',
    router: 'public',
    urlPath: '/public/company/:id',
  },
  {
    viewPath: 'components/views/InvestorPortfolioDetail',
    router: 'public',
    urlPath: '/investor/portfolio-detail/:id',
  },
  {
    viewPath: 'components/views/OnboardingSelectUserType',
    router: 'private',
    type: 'any',
    layout: 'components/global/Layouts/Onboarding.layout',
    urlPath: '/on-boarding/select-user-type',
  },
  {
    viewPath: 'components/views/OnboardingBasicInfo',
    router: 'private',
    type: 'any',
    layout: 'components/global/Layouts/Onboarding.layout',
    urlPath: '/on-boarding/basic-info',
  },
];

const founder = routes.filter(route => (['any', 'founder'].includes(route?.type) || route.router === 'public'));
const investor = routes.filter(route => (['any', 'investor'].includes(route?.type) || route.router === 'public'));
const developer = routes.filter(route => (['any', 'investor', 'founder'].includes(route?.type) || route.router === 'public'));
const publicRoutes = routes.filter(route => (route.router === 'public'));

export default {
  routes,
  founder,
  investor,
  dev: developer,
  public: publicRoutes,
};
