import { useEffect } from 'react';
import notifierSignal from '../../../signals/notifier.signal';

const Notifier = () => {
  const { title, message = [], timeOut } = notifierSignal.value;
  const messages = (!Array.isArray(message) ? [message] : message).filter(msg => !!msg);

  useEffect(() => {
    if (!messages.length) return;

    const x = setTimeout(() => {
      notifierSignal.update({
        message: undefined,
      });
    }, timeOut);

    // eslint-disable-next-line
    return () => clearTimeout(x);
  }, [messages]);

  if (messages && messages.length > 0) {
    return (
      <div className="bg-white border border-primary text-center text-primary animate__animated animate__slideInDown animate__bounce animate__faster" style={{ background: 'white', position: 'absolute', width: '100%', padding: '1rem', top: 0, zIndex: '1000', fontSize: '18px' }}>
        {title && <p className="p-0 m-0"><b>{title}</b></p>}
        <ul className="inline-block p-0 m-0" style={{ listStyle: 'none' }}>
          { messages && messages.length && messages?.map((msg, liidx) => <li key={`err-${liidx}`}>&bull; {msg}</li>)}
        </ul>
      </div>
    );
  }

  return null;
};

export default Notifier;
